/* eslint-disable import/no-duplicates */
import cx from 'classnames';
import Link from 'next/link';
import React from 'react';

import PartLazyImage from '../../part-lazy-image';

import Arrow from './assets/arrow.svg';
import FoliumIcon from './assets/folium.svg';
import ManBackground from './assets/man-background.svg';
import manImage from './assets/man.png';
import manImagePlaceholder from './assets/man.png?placeholder';

import styles from './part-banner-connecting-talent-and-companies.module.scss';

export default function PartBannerConnectingTalentAndCompanies({ className }) {
  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.mainTextContainer}>
        <h1 className={styles.mainText}>
          <div>Connecting</div>
          <div style={{ whiteSpace: 'nowrap' }}>
            {/* We add nowrap to prevent jumping text to new line on phone due to
             satoshi font smaller */}
            <i>Top IT Talent</i>
            {' '}
            with
          </div>
          <div><i>Top Companies</i></div>
        </h1>
        <Link
          className={styles.mainTextButton}
          href="/contact-us"
        >
          <span>Request a talent</span>
          <Arrow />
        </Link>
      </div>
      <div className={styles.bannerImageContainer}>
        <PartLazyImage
          src={manImage}
          placeholder={manImagePlaceholder}
          className={styles.bannerImageManContainer}
        />
        <ManBackground className={styles.bannerImageManBackground} />
        <div className={styles.canadianCompanyTextContainer}>
          <div>
            <FoliumIcon />
          </div>
          <span>
            - Proudly Canadian company
          </span>
        </div>
        {/* <TextCanadianCompanyWoShadow
        className={styles.bannerImageTextCanadianCompanyWoShadow} /> */}
      </div>
    </div>
  );
}
