import cx from 'classnames';
import React from 'react';

import PartLazyImage from '../../part-lazy-image';

import ImgHiringAdvisory from './assets/icon-hiring-advisory-2x.png';
import ImgHiringAdvisoryPlaceholder from './assets/icon-hiring-advisory-2x.png?placeholder';
import ImgPermanentHiring from './assets/icon-permanent-hiring-2x.png';
import ImgPermanentHiringPlaceholder from './assets/icon-permanent-hiring-2x.png?placeholder';
import ImgTeamRecruitment from './assets/icon-team-recruitment-2x.png';
import ImgTeamRecruitmentPlaceholder from './assets/icon-team-recruitment-2x.png?placeholder';
import ImgTemporaryContract from './assets/icon-temporary-contract-2x.png';
import ImgTemporaryContractPlaceholder from './assets/icon-temporary-contract-2x.png?placeholder';
import image from './assets/image-laptop.png';
import imagePlaceholder from './assets/image-laptop.png?placeholder';

import styles from './part-our-services.module.scss';

export default function PartOurServices({ className }) {
  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.titleAndDescription}>
        <h2>
          Our
          {' '}
          <i>Services</i>
        </h2>
        <p>
          Take a look at our expert recruitment services we provide
          {' '}
          <br className="visibleOnDesktopAndTablet" />
          with commitment
          {' '}
          <br className="visibleOnPhone" />
          to value and quality.
        </p>
      </div>
      <div className={styles.servicesContainer}>
        <div className={styles.servicesImage}>
          <PartLazyImage
            src={image}
            placeholder={imagePlaceholder}
          />
        </div>
        <div className={styles.servicesGridContainer}>
          <div className={styles.servicesGrid}>
            <div className={styles.serviceItem}>
              <div className={styles.serviceItemIcon}>
                <PartLazyImage
                  src={ImgTemporaryContract}
                  placeholder={ImgTemporaryContractPlaceholder}
                />
              </div>
              <div className={styles.serviceItemTitle}>
                Temporary and
                {' '}
                <br className={styles.desktopLineBreak} />
                Contract Staffing
              </div>
            </div>

            <div className={styles.serviceItem}>
              <div className={cx(styles.serviceItemIcon, styles.serviceItemIcon_permanentHiring)}>
                <PartLazyImage
                  src={ImgPermanentHiring}
                  placeholder={ImgPermanentHiringPlaceholder}
                />
              </div>
              <div className={styles.serviceItemTitle}>
                Permanent or
                {' '}
                <br className={styles.desktopLineBreak} />
                Full-
                <br className={styles.mobileLineBreak} />
                Time hiring
              </div>
            </div>

            <div className={styles.serviceItem}>
              <div className={styles.serviceItemIcon}>
                <PartLazyImage
                  src={ImgTeamRecruitment}
                  placeholder={ImgTeamRecruitmentPlaceholder}
                />
              </div>
              <div className={styles.serviceItemTitle}>
                Full Team
                {' '}
                <br className={styles.desktopLineBreak} />
                recruitment
              </div>
            </div>

            <div className={styles.serviceItem}>
              <div className={styles.serviceItemIcon}>
                <PartLazyImage
                  src={ImgHiringAdvisory}
                  placeholder={ImgHiringAdvisoryPlaceholder}
                />
              </div>
              <div className={styles.serviceItemTitle}>
                IT hiring
                {' '}
                <br className={styles.desktopLineBreak} />
                Advisory
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}
