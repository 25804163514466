import cx from 'classnames';
import React from 'react';

import PartDesignElementsContainer, { designElementsParentContainerClass }
  from '../part-design-elements-container';
import PartDesignHelper from '../part-design-helper';
import PartDiscussHiringNeeds from '../part-discuss-hiring-needs';
import PartFooter from '../part-footer';
import PartHeader from '../part-header';

import BlueDotsLeft from './assets/blue-dots.svg';
import PartBannerConnectingTalentAndCompanies from './part-banner-connecting-talent-and-companies';
import PartIndustryInsights from './part-industry-insights';
import PartOurServices from './part-our-services';
import PartTrustedBy from './part-trusted-by';
import PartWhyWeRightChoice from './part-why-we-right-choice';

import styles from './page-home.module.scss';

import Phone0424FromHeaderToWhyRightChoice
  from './design/design-iphone-2023-04-24-from-header-to-why-right-choice.jpg';
import Phone0427FromInsightsToLetsDiscuss
  from './design/design-iphone-2023-04-27-from-insights-to-lets-discuss.jpg';
import Phone0428Footer from './design/design-iphone-2023-04-28-footer.jpg';
import Phone0602Footer from './design/design-iphone-2023-06-02-footer.jpg';
import Pc0424FromHeaderToWhyRightChoice
  from './design/design-pc-2023-04-24-from-header-to-why-right-choice.jpg';
import Pc0427FromInsightsToLetsDiscuss
  from './design/design-pc-2023-04-27-from-insights-to-lets-discuss.jpg';
import Pc0428Footer from './design/design-pc-2023-04-28-footer.jpg';
import Pc0602Footer from './design/design-pc-2023-06-02-footer.jpg';

export default function PageHome({ posts }) {
  return (
    <div className={cx(styles.container, designElementsParentContainerClass)}>
      <PartDesignHelper designs={{
        Phone0424FromHeaderToWhyRightChoice,
        Phone0427FromInsightsToLetsDiscuss,
        Phone0428Footer,
        Phone0602Footer,
        Pc0424FromHeaderToWhyRightChoice,
        Pc0427FromInsightsToLetsDiscuss,
        Pc0428Footer,
        Pc0602Footer,
      }}
      />
      <PartHeader />
      <PartBannerConnectingTalentAndCompanies className={styles.banner} />
      <PartOurServices className={styles.partOurServices} />
      <PartTrustedBy className={styles.partTrustedBy} />
      <PartWhyWeRightChoice className={styles.partWhyWeRightChoice} />
      <PartIndustryInsights className={styles.partIndustryInsights} posts={posts} />
      <PartDiscussHiringNeeds className={styles.discussHiringNeeds} />
      <PartFooter />
      <PartDesignElementsContainer>
        <BlueDotsLeft className={styles.blueDotsLeft} />
        <div className={styles.bubbleRightTop} />
        <div className={styles.bubbleRightMiddle} />
        <div className={styles.bubbleRightBottom} />
        <div className={styles.bubbleLeft} />
        <div className={styles.bubbleRightTopMobile} />
        <div className={styles.bubbleRightMiddleMobile} />
        <div className={styles.bubbleRightBottomMobile} />
        <div className={styles.bubbleLeftMobile} />
      </PartDesignElementsContainer>
    </div>
  );
}

export const getStaticProps = async () => {
  const { listPostContent } = await import('../lib-content/insights');
  const posts = listPostContent(1, 3);

  return {
    props: {
      posts,
    },
  };
};
