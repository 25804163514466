import cx from 'classnames';
import moment from 'moment/moment';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';

import PartLazyImage from '../../part-lazy-image';

import styles from './part-industry-insights.module.scss';

export default function PartIndustryInsights({ className, posts = [] }) {
  const router = useRouter();
  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.title}>
        <h2>
          Industry
          {' '}
          <i>Insights</i>
        </h2>
      </div>
      <div className={styles.articlesList}>
        <Swiper
          className={styles.swiperContainer}
          modules={[Pagination]}
          pagination={{ clickable: true }}
          {...{
            centeredSlides: true,
            centeredSlidesBounds: true,
            slidesPerView: 'auto',
            spaceBetween: 30,
            breakpoints: {
              0: {
                enabled: true,
                slidesPerView: 1,
                spaceBetween: 10,
                pagination: {
                  enabled: true,
                },
              },
              767: {
                enabled: false,
                slidesPerView: posts.length > 3 ? 3 : posts.length,
                spaceBetween: 30,
                pagination: {
                  enabled: false,
                },
              },
            },
            loop: true,
          }}
        >
          {posts.map(({
            thumbnail: imageSrc,
            title, date, author, tags = [],
          }, index) => (
            // eslint-disable-next-line max-len
            // eslint-disable-next-line react/no-array-index-key, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <SwiperSlide className={styles.swiperSlide} key={`slide-${title}-${date}-${index}`} onClick={() => title && router.push(`/insights/${title}`)}>
              <div
                className={styles.article}
              >
                <PartLazyImage
                  className={styles.articleImage}
                  src={imageSrc}
                />
                <div className={styles.articleTags}>
                  {tags.map((tag) => (
                    <div className={styles.articleTag} key={tag}>
                      {tag}
                    </div>
                  ))}
                </div>
                <Link className={styles.articleTitle} href={`/insights/${title}`}>
                  {title}
                </Link>
                <div className={styles.articleTitleAndDateDivider} />
                <div className={styles.articleDateAndAuthor}>
                  <div className={styles.articleDate}>
                    {moment(date).format('MMM DD, YYYY')}
                  </div>
                  <i />
                  <div className={styles.articleAuthor}>
                    {author}
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
