export default [
  {
    title: '15+',
    subTitle: 'Years',
    content: `You’ll gain access to our
              15+ years of experience
              in software development
              and recruitment.`,
  }, {
    title: '8',
    subTitle: 'Geolocations',
    content: `We pride ourselves on
              our global reach with 8 strategically located
              headquarters around
              the world.`,
  }, {
    title: '2%',
    subTitle: 'Top IT talent',
    content: `You gain access to the
              top 2% of senior-level IT
              professionals in Canada,
              the US, and Europe.`,
  },
];
