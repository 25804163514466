import cx from 'classnames';
import React from 'react';

import LogoBell from './assets/bell.svg';
import LogoCIBC from './assets/cibc.svg';
import LogoCox from './assets/cox.svg';
import DesignElement from './assets/design-element.svg';
import LogoIBM from './assets/ibm.svg';

import styles from './part-trusted-by.module.scss';

/**
 * TODO: Investigate: makes no sense to use svg,
 * because inside the svg is a bitmap and it was displayed crookedly
 */

export default function PartTrustedBy({ className }) {
  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.titleAndDescription}>
        <h2>
          Trusted
          {' '}
          <i>by</i>
        </h2>
        <p>
          Here&apos;s a glimpse of some of the
          {' '}
          <br className="visibleOnPhone" />
          companies we&apos;ve helped
          <br className="visibleOnDesktopAndTablet" />
          {' '}
          to achieve their
          <br className="visibleOnPhone" />
          {' '}
          hiring goals.
        </p>
      </div>

      <div className={styles.logosListContainer}>
        <DesignElement className={cx(styles.designElement, styles.designElement_left)} />
        <DesignElement className={cx(styles.designElement, styles.designElement_right)} />
        <div className={styles.logosList}>
          <div className={cx(styles.logo, styles.logo_ibm)}>
            <LogoIBM />
          </div>
          <div className={cx(styles.logo, styles.logo_cox)}>
            <LogoCox />
          </div>
          <div className={cx(styles.logo, styles.logo_cibc)}>
            <LogoCIBC />
          </div>
          <div className={cx(styles.logo, styles.logo_bell)}>
            <LogoBell />
          </div>
        </div>
      </div>
    </div>
  );
}
