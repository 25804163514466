import cx from 'classnames';
import React from 'react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';

import IconSwirlingArrowMobile from './assets/icon-swirling-arrow-mobile.svg';
import IconSwirlingArrow from './assets/icon-swirling-arrow.svg';

import styles from './part-why-we-right-choice.module.scss';

import data from './data';

export default function PartWhyWeRightChoice({ className }) {
  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.titleAndDescription}>
        <h2>
          Why we’re
          <br />
          <i>the right choice</i>
        </h2>
        <p>
          We will bring the breathe of our experience and
          industry knowledge to help you succeed
        </p>
        <div className={styles.swirlingArrowContainer}>
          <IconSwirlingArrow className={styles.swirlingArrow} />
          <IconSwirlingArrowMobile className={styles.swirlingArrowMobile} />
        </div>
      </div>
      <div className={styles.reasonsForChoosing}>
        <Swiper
          className={styles.swiperContainer}
          modules={[Pagination]}
          pagination={{ clickable: true }}
          {...{
            slidesPerView: 'auto',
            // spaceBetween: 30, // TODO Remove after 06/15/2023
            breakpoints: {
              0: {
                enabled: true,
                slidesPerView: 1,
                spaceBetween: 10,
                pagination: {
                  enabled: true,
                },
              },
              767: {
                enabled: false,
                slidesPerView: 3,
                spaceBetween: 30,
                // spaceBetween: `${96 / 16}rem`, // TODO Remove after 06/15/2023
                pagination: {
                  enabled: false,
                },
              },
            },
            loop: true,
          }}
        >
          {data.map(({ title, subTitle, content }, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <SwiperSlide key={`slide-${title}-${subTitle}-${content}-${index}`}>
              <div className={styles.reasonContainer}>
                <div className={styles.reason}>
                  <div className={styles.reasonTitle}>
                    {title}
                  </div>
                  <div className={styles.reasonSubtitle}>
                    {subTitle}
                  </div>
                  <div className={styles.reasonDescription}>
                    {content}
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
