import cx from 'classnames';
import omit from 'lodash/omit';
import React, { useState, useRef } from 'react';

import { legalConsentOptions, formsBaseUrl } from '../../site-config';

import PartInput from './part-input';

import styles from './part-discuss-hiring-needs.module.scss';

export default function PartDiscussHiringNeeds({ className }) {
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const formRef = useRef(null);

  const handleChangeInput = (e) => {
    // eslint-disable-next-line no-shadow
    setFormData((formData) => ({ ...formData, [e.target.name]: e.target.value }));
    // eslint-disable-next-line no-shadow
    setFormErrors((formErrors) => omit(formErrors, [e.target.name]));
  };

  const validateSubmit = () => {
    const errors = {};
    const {
      firstName, lastName, phone, email,
    } = formData;

    if (firstName?.length < 2) errors.firstName = 'Min length is 2 symbols';
    if (!firstName) errors.firstName = 'Please enter your first name';
    if (lastName?.length < 2) errors.lastName = 'Min length is 2 symbols';
    if (!lastName) errors.lastName = 'Please enter your last name';
    if (!email?.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      errors.email = 'Please enter a valid email';
    }
    if (!phone?.match(/^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g)) {
      errors.phone = 'Please enter a valid phone';
    }

    setFormErrors(errors);

    const isValid = Object.keys(errors).length === 0;

    return isValid;
  };

  // eslint-disable-next-line consistent-return
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateSubmit()) return false;

    const fields = [];

    fields.push(...Object.entries(formData)
      .map(([k, v]) => ({
        name: k,
        value: v,
      })));

    const currentUrl = window.location.pathname;

    fields.push({
      name: 'conversion_url',
      value: currentUrl,
    });

    fetch(`${formsBaseUrl}/a01a0e54-48f9-4cac-8aca-a5b24d379a03`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ fields, legalConsentOptions }),
    })
      .then(() => {
        setFormData({});
        setFormErrors({});
        // eslint-disable-next-line no-alert
        window.alert('Thank you.');
        // TODO Add thank you message;
      })
      .catch((error) => {
        // eslint-disable-next-line no-alert
        alert('Something went wrong. Please try again later.');
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.titleAndDescription}>
        <h2>
          Let’s Discuss Your
          {' '}
          <br className="visibleOnPhone" />
          <i>Hiring Needs</i>
        </h2>
        <p>
          Finding the right fit for your company
          <br className="visibleOnPhone" />
          {' '}
          shouldn&apos;t be difficult. Reach
          {' '}
          <br className="visibleOnDesktopAndTablet" />
          out to us
          {' '}
          <br className="visibleOnPhone" />
          and see the difference!
        </p>
      </div>
      <form className={styles.form} ref={formRef} onSubmit={handleSubmit}>
        <div className={styles.formInputsContainer}>
          {/*
          Note: you can see input invalid state by using native validator
           (makes input:invalid css applying)
           or add data-invalid="true" on input
           */}
          <div className={cx(styles.formInput, styles.formInput_firstName)}>
            <span>First Name</span>
            <PartInput
              name="firstName"
              placeholder="First Name"
              onChange={handleChangeInput}
              value={formData.firstName}
              inputError={formErrors.firstName}
            />
          </div>
          <div className={cx(styles.formInput, styles.formInput_lastName)}>
            <span>Last Name</span>
            <PartInput
              name="lastName"
              placeholder="Last Name"
              onChange={handleChangeInput}
              value={formData.lastName}
              inputError={formErrors.lastName}
            />
          </div>
          <div className={styles.formInput}>
            <span>Phone number</span>
            <PartInput
              name="phone"
              placeholder="Phone number"
              onChange={handleChangeInput}
              value={formData.phone}
              inputError={formErrors.phone}
            />
          </div>
          <div className={styles.formInput}>
            <span>Business Email</span>
            <PartInput
              name="email"
              placeholder="Business Email"
              onChange={handleChangeInput}
              value={formData.email}
              inputError={formErrors.email}
            />
          </div>
          <div className={cx(styles.formInput, styles.formInput_message)}>
            <span>Message</span>
            <PartInput
              type="textarea"
              name="message"
              placeholder="Message"
              value={formData.message || ''}
              onChange={handleChangeInput}
            />
          </div>
        </div>
        <div className={styles.submitButtonContainer}>
          {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
          <button
            className={styles.submitButton}
            type="submit"
            onMouseOver={validateSubmit}
            disabled={Object.keys(formErrors).length > 0}
            title={(Object.keys(formErrors).length > 0) ? 'Form has errors' : undefined}
          >
            Submit Message
          </button>
        </div>
      </form>
    </div>
  );
}
