import cx from 'classnames';
import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import IconWarning from '../assets/icon-warning.svg';

import styles from './part-input.module.scss';

export default function PartInput({
  className,
  value = '',
  name,
  placeholder,
  type = 'text',
  onChange = () => null,
  maxLength,
  inputError,
}) {
  let Element = 'input';
  if (type === 'textarea') Element = TextareaAutosize;
  return (
    <div className={cx(
      styles.container,
      { [styles.hasError]: !!inputError },
      className,
    )}
    >
      <Element
        value={value}
        type={type}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        maxLength={maxLength}
      />
      <IconWarning />
      <i className={styles.errorText}>{inputError}</i>
    </div>
  );
}
